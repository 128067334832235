<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { Game } from "@/types";

const { open } = useAppModals();
const { data: appInitData } = useAppInitData();

const props = withDefaults(
	defineProps<{
		category?: string;
		title?: string;
		icon?: string;
		games: Game[];
		rowsPerSlide?: number;
		isShowAll?: boolean;
		hideNextSlidesMobile?: boolean;
	}>(),
	{ rowsPerSlide: 1, isShowAll: true, hideNextSlidesMobile: false }
);

const { t } = useT();
const router = useRouter();

const sliderRef = ref();

const sliderOptions: KeenSliderOptions = {
	mode: "free-snap",
	slides: { perView: "auto", spacing: 0 }
};

const preparedGames = computed(() =>
	props.games.reduce<Array<Array<Game>>>((acc, item, index) => {
		const chunkIndex = Math.floor(index / props.rowsPerSlide);
		if (!acc[chunkIndex]) {
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, [])
);

const handleNavigateToGames = () => {
	if (!props.category) {
		return;
	}

	if (props.category === "recent-games") {
		router.push("/issues/favorites/");
		return;
	}
	if (props.category === "new-games") {
		router.push("/issues/new-games/");
		return;
	}

	router.push(`/issues/${props.category}/`);
};
const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
const { handleOpenGame } = useOpenGame(open);

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		const lastGames = appInitData.value?.lastGames || [];
		if (lastGames.includes(game.id)) {
			appInitData.value?.lastGames &&
				(appInitData.value.lastGames = [game.id as number, ...lastGames.filter((el) => el !== game.id)]);
		} else {
			appInitData?.value?.lastGames && (appInitData.value.lastGames = [game.id as number, ...lastGames]);
		}
		handleOpenGame(game.slug);
	}
};

watch(
	() => props.games.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<section :class="[{ hideNextSlidesMobile }]">
		<MHomeSectionHeader
			:title="title"
			:icon="icon"
			:tidTitle="`slider-title-${getTitle(title)}`"
			:tidShow="`slider-${getTitle(title)}-all`"
			:is-show-all="isShowAll"
			@handle-click="handleNavigateToGames"
		/>
		<ASlider ref="sliderRef" :options="sliderOptions">
			<template v-if="rowsPerSlide === 1">
				<MGame
					v-for="(game, index) in games"
					:key="game.id"
					:game="game"
					class="keen-slider__slide"
					:data-tid="getDataTid(index)"
					@toggle-favorite="handleToggleToFavoriteClick(game)"
					@play="handlePlayClick(game)"
				/>
			</template>
			<template v-else>
				<div
					v-for="(item, index) in preparedGames"
					:key="`${category}-${index}`"
					class="keen-slider__slide slide-column"
				>
					<MGame
						v-for="game in item"
						:key="game.id"
						:game="game"
						@toggle-favorite="handleToggleToFavoriteClick(game)"
						@play="handlePlayClick(game)"
					/>
				</div>
			</template>
		</ASlider>
	</section>
</template>
<style lang="scss" scoped>
section {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-down(lg) {
		padding: 0 16px;
	}

	&.hideNextSlidesMobile:deep(.keen-slider) {
		mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
	}

	&:deep(.keen-slider) {
		padding-top: 24px;
		@include media-breakpoint-up(md) {
			mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) calc(100% - 60px), rgba(0, 0, 0, 0) 100%);
		}

		@include media-breakpoint-down(md) {
			padding-top: 20px;

			&:not([data-keen-slider-disabled]) {
				overflow: visible;
			}
		}
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: calc(172px + 8px);
		padding-right: 8px;

		@include media-breakpoint-down(md) {
			width: calc(124px + 12px);
			padding-right: 12px;

			&:last-of-type {
				padding-right: 0;
				width: 124px;
			}
		}

		&.slide-column {
			display: flex;
			flex-direction: column;
			gap: 12px;

			@include media-breakpoint-down(md) {
				gap: 12px;
			}
		}
	}
}
</style>
